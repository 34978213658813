<template>

  <footer class="footer">

    <div v-show="!bestlip_visible" class="w-100">

      <div v-show="betslip_count > 0" class="row odds-footer text-black justify-content-between" id="outright-betslip-footer">
        <div >Total Odds {{ odds | formatOdds }} </div>
        <div>Possible Payout <span> {{ payout |currency }}</span></div>
      </div>

      <div class="row d-flex justify-content-between wazi-primary pad-1 mb--1" style="padding: 18px 2px 0px 2px;">

        <div class="col-auto">
          <a  @click="showMenu">
            <MenuIcon v-bind:size="iconSize" ></MenuIcon>
            <pre v-bind:class="homePageFontColor" class="white-txt" style="font-size: 11px !important; text-align: center;;margin-top: -11px;">Menu</pre>
          </a>
        </div>

        <div class="col-auto">
          <router-link to="/live">
            <lazy-image v-show="liveGames === 0" class="lozad" v-bind:src="getSportIcon(1)" alt="live page icon" v-bind:width="iconSize" />
            <lazy-image v-show="liveGames > 0 " class="lozad" v-bind:src="getURL('/assets/images/soccer.svg')" v-bind:width="iconSize" alt="live page icon" />
            <span class="live-counter">{{ liveGames }}</span>
            <pre v-bind:class="livePageFontColor" class="white-txt"
                 style="font-size: 11px !important; text-align: center;">Live</pre>
          </router-link>
        </div>

        <div v-if="betslip_count > 0 && betslip_count < 10" class="col-auto" @click="showBetslip">
            <span class="outright-slip-counter counter" id="counter-1" v-text="betslip_count"></span>
        </div>

        <div v-if="betslip_count > 9" class="col-auto" @click="showBetslip">
          <span class="outright-slip-counter counter" id="counter-2" v-text="betslip_count"></span>
        </div>

        <div v-if="betslip_count === 0" class="col-auto" @click="showBetslip">
          <span class="outright-slip-counter counter" id="counter-0">0</span>
        </div>

        <div class="col-auto">
          <router-link to="/my-bets">

            <ListIcon v-if="activeBets > 0 " v-bind:size="iconSize" fill-color="yellow" style="padding-top: 1px;margin-top: -10px;"></ListIcon>
            <ListIcon v-if="activeBets === 0 " v-bind:size="iconSize" fill-color="white" style="padding-top: 1px;margin-top: -10px;"></ListIcon>
            <span class="live-counter" v-text="activeBets"></span>
            <pre v-bind:class="historyPageFontColor" class="white-txt" style="text-align: center;margin-top: -11px;">My Bets</pre>
          </router-link>
        </div>

        <div v-show="isLoggedIn" class="col-auto">

          <router-link to="/profile" class="white-txt bold" >
            <person-icon v-bind:size="iconSize" fill-color="white" style="padding-top: 1px;margin-top: -10px;"></person-icon>
            <pre class="white-txt" style="text-align: center;margin-top: -11px;">
              Bal {{ bal }}
            </pre>
          </router-link>

        </div>

        <div v-show="!isLoggedIn" class="col-auto">

          <router-link to="/join" class="white-txt bold" >
            <person-icon v-bind:size="iconSize" fill-color="white" style="padding-top: 1px;margin-top: -10px;"></person-icon>
            <pre class="white-txt" style="text-align: center;margin-top: -11px;">
              Login
            </pre>
          </router-link>

        </div>

      </div>

    </div>

    <div id="betslip-modal" v-bind:class="modalClass">

      <div id="status"></div>

      <!-- Modal content -->
      <div v-bind:class="modalContentClass" id="betmobile">

        <div class="faso-modal-header d-flex justify-content-between" style="background-color: var(--primary-dark);">

          <div class="d-flex justify-content-start">
            <div v-show="betslip_count > 0" style="font-size: var(--font-large);">BETSLIP</div>
            <div v-show="betslip_count === 0" style="font-size: var(--font-large);">BOOKING CODE</div>
          </div>

          <div class="d-flex justify-content-end">
            <div style="font-size: var(--font-largest);color: var(--white);" id="faso-close">&times;</div>
          </div>

        </div>

        <div class="faso-modal-header d-flex justify-content-between" style="background-color: #8c8c8c;">

          <div class="d-flex justify-content-start">
            <div v-show="betslip.total === 1" style="font-size: var(--font-large);">Single Bet</div>
            <div v-show="betslip.total > 1" style="font-size: var(--font-large); padding-right: 3px">Multi Bet</div>
            <div v-show="betslip.total > 1"> ({{ betslip.total }})</div>
          </div>

          <div v-show="parseInt(betslip_count) > 0" class="d-flex justify-content-end">
            <div style="font-size: var(--font-small); padding-right: 10px" @click="clearOutrightBetSlip">Remove All</div>
          </div>

        </div>

        <div class="faso-modal-body" style="height: 20em;overflow-y: auto;">

          <Pick v-show="betslip_count > 0" v-for="(bet,index) in betslip.picks" v-bind:key="index" v-bind:bet="bet"></Pick>

          <div v-show="betslip_count > 0">

            <div class="space-1" style="border-top: dotted var(--gray) 2px;margin: -5px -10px 0px -10px;"></div>

            <div class="row" style="margin-top: -5px;font-size: 0.8em;">

              <div class="col-12" style="padding: 10px 0 0 10px;text-align: left">
                <div class="form-group form-check">
                  <input type="checkbox" class="form-check-input" v-model="accept_odds_changes">
                  <label class="form-check-label">Accept Odds Changes</label>
                </div>
              </div>

            </div>

          </div>

          <section v-show="betslip_count === 0">

            <div class="form-group">
              <label style="color: black">Do you have a booking code? Enter your booking code below</label>
              <input type="text" class="form-control input-login" placeholder="e.g FBXXX" v-model="booking_code">
            </div>

          </section>

        </div>

        <div class="" style="position: fixed;bottom: 0;width: 100%;/*height: 12em*/;max-width: 768px;">

          <div class="space-1  busta-modal-footer d-flex flex-column pb-0" style="border-top: solid var(--black) 1px;background-color: white;"></div>

          <div v-if="betslip_count !== 0" class="faso-modal-footer d-flex flex-column pb-0" style="background-color: var(--white); padding: 2px 16px;">

            <div class="w-100 d-flex justify-content-between" style="color: var(--white)">
              <div class="b-txt">Total Odds</div>
              <div class="b-txt">
                <span>{{ betslip.odds | formatOdds }}</span>
              </div>
              <input type="hidden" id="total_odd">
            </div>

            <div class="w-100 d-flex justify-content-between" style="margin-bottom: 10px;">
              <div class="b-txt">Stake</div>
              <input type="number" name="amount" id="bet_amount" value="50" v-model="stake">

            </div>

          </div>

          <div class=" busta-modal-footer d-flex flex-column pb-0" style="background-color: var(--primary-dark);">

            <div v-if="betslip_count !== 0" class="w-100 d-flex justify-content-between" style="margin-top: 10px;padding: 2px 16px;" v-on:click="seen = !seen">
              <div class="b-txt" style="color: var(--white);font-weight: bold;">Possible winnings</div>
              <div class="b-txt" style="color: var(--white);font-weight: bold;">{{ betslip.winning | currency }}</div>
            </div>

            <div v-if="betslip_count !== 0" class="w-100 d-flex justify-content-between" style="margin-bottom: 10px;padding: 2px 16px;">
              <div class="b-txt" style="color: var(--white);font-weight: bold;">Final Payout</div>
              <div class="b-txt" style="color: var(--white);font-weight: bold;">{{ betslip.payout | currency }}</div>
            </div>

            <div v-if="has_suspended_picks" class="w-100 remove-locked-outcome" @click="removeSuspendedOutrightPicks">
              Remove Locked Outcomes
            </div>

            <div v-else-if="betslip_count === 0" class="w-100 load-booking-code" @click="loadBookingCode">
              Load Booking Code
            </div>

            <div v-else class="w-100 mb-1 d-flex justify-content-between" style="margin-bottom: 20px !important;padding: 2px 16px;">

              <button class="btn-bet " style="width: 35% !important;" v-bind:class="loading" disabled>
                <lazy-image style="height: .7em;" class="lozad" v-bind:src="getURL('/assets/icons/share.svg')"/>
                <span style="margin-left: 5px">Share</span>
              </button>

              <button class="btn-bet" style="width: 50% !important;" v-bind:class="loading" @click="placeBet">
                Place Bet
              </button>

            </div>

          </div>

        </div>

      </div>

    </div>

    <span id="open-sharebet" class="hidden"></span>

    <span id="betslip-init" class="hidden"></span>

    <!-- The Modal -->
    <div class="sharebet-modal" id="sharebet-modal">

      <div class="sharebet-modal-content">

        <div class="row d-flex">
          <div class="col-12 share-bet-title justify-content-center">
            <strong>Share Your bet</strong>
          </div>
          <div class="col-12 share-bet-desc" @click="copyCode">
            Your booking code
            <div class="booking-code">
              <strong>{{ code }}</strong>
            </div>
            <strong>book#{{ code }}#stake to 40600</strong>
            <div style="font-size: 0.5em;">{{ copyBookingCode }}</div>

          </div>
        </div>

        <div class="row d-flex">

          <div class="col-12 btn-group-sm w-100">

            <a
                data-action="share/whatsapp/share" target="_blank"
                onclick="window.open('whatsapp://send?text=' + document.getElementById('share-bet-msg').textContent); return false;"
                class="btn share-bet-social whatsapp" style="font-size: 0.6em;">
              WhatsApp
            </a>


            <a
                href="https://www.facebook.com/sharer/sharer.php?u=&t="
                title="Share on Facebook"
                onclick="window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(document.getElementById('share-bet-link').textContent) + '&t=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;"
                target="_blank"
                class="btn share-bet-social facebbok" style="font-size: 0.6em;">
              Facebook
            </a>

            <a
                href="https://twitter.com/intent/tweet?"
                target="_blank" title="Tweet"
                onclick="window.open('https://twitter.com/intent/tweet?text=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;"
                class="btn share-bet-social twitter" style="font-size: 0.6em;">
              Twitter
            </a>

          </div>

          <div class="col-12">
            <div class="share-bet-link" id="share-bet-link">https://tapabet.co.ke/share/{{ code }}</div>
            <div class="hidden" id="share-bet-code">{{ code }}</div>
            <div class="hidden" id="share-bet-odds">{{ odds }}</div>
            <div class="hidden" id="share-bet-msg">{{ msg }}</div>
            <div class="share-bet-copy" @click="copyShareBetLink">{{ copyText }}</div>
          </div>

        </div>
      </div>
    </div>

  </footer>

</template>

<script>

import axios from "@/services/api";
import Pick from './Pick'
const MenuIcon= () => import(/* webpackChunkName: "material-icons" */ 'vue-material-design-icons/Menu.vue');
const ListIcon= () => import(/* webpackChunkName: "material-icons" */  'vue-material-design-icons/ViewList.vue');

export default {
  name: "bottom-navigation",
  props: {
    page: {
      required: true
    },
  },
  data: function () {
    return {
      stake: 50,
      loading: '',
      bestlip_visible: false,
      code: '',
      msg: '',
      share_odds: '',
      copyText: 'Copy',
      copyBookingCode: 'Click to copy',
      myProfile: this.getProfile(),
      accept_odds_changes: true,
      previous_odds: 0,
      odds_changed: false,
      seen: false,
      withholding_tax: 0,
      booking_code: ''
    }
  },
  methods: {
    loadBookingCode: function(){

      if(this.booking_code.trim().length > 4 ) {

        if(this.currentRouteName !== "share") {

          this.$router.push({name: 'share', params: {code: this.booking_code.trim()}});

        } else {

          this.EventBus.$emit('share:code', this.booking_code.trim());
        }

      } else {

        this.setError("invalid code","Please enter a valid booking code to proceed");

      }

    },
    shareBetMessage: function(){

      var code = this.code;
      var odds = this.share_odds;
      var toWin = this.formatCurrency(parseFloat(odds) * 200);
      this.msg =   "Cheki Hii bet nimeekelea kwa tapabet. If you stake 200 and you could win Ksh. "+toWin+". Betslip ndio Hii >> \n\n\n\n https://tapabet.co.ke/share/"+code;

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    },
    getSportAlt: function (sport_id) {

      switch (parseInt(sport_id)) {

        case 1:
          return "Soccer Icon";

        case 2:
          return "basketball icon";

        case 4:
          return "hockey icon";

        case 5:
          return "tennis icon";

        case 22:
          return "/assets/icons/dart.svg";

        case 20:
          return "/assets/icons/tabletennis.svg";

        case 6:
          return "/assets/icons/handball.svg";

        case 12:
          return "/assets/icons/rugby.svg";

        case 10:
          return "/assets/icons/boxing.svg";

        case 31:
          return "/assets/icons/badminton.svg";

        case 3:
          return "/assets/icons/baseball.svg";
      }

      return ""
    },
    show: function () {

      this.jQuery('#betslip-modal').modal('show');
      //var modal = document.getElementById("betslip-modal");
      //modal.style.display = "block";
      this.bestlip_visible = true;
    },
    tax: function(){
      const wage_tax = 7.5;
      const winning_tax = 20/100;
      let stake = this.stake;
      let odd = this.betslip.odds;

      let stake_after_tax = stake - (stake*wage_tax)/100;

      let winning = odd * stake_after_tax;
      let w_tax = (winning - stake_after_tax) * winning_tax;

      this.withholding_tax = w_tax;

    },
    placeBet: function () {

      this.reset();
      var p = this.getProfile();

      if(!p) {

        this.setError("Login","Please login to proceed");
        this.setValue("placeBet",2);
        this.$router.push({ name: 'login', params: { } });
        return;
      }

      var bet_amount = this.getValue("stake");
      var booking_code = this.getValue("booking_code");
      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      if (bet_amount < 1) {

        this.setError("Invalid Bet Amount", "Please enter bet amount greater of 1Ksh or more ");
        return;
      }

      var betslipData = this.betslip;

      if (betslipData.total === 0) {

        this.setError("Invalid Bets", "Please Select atleast one outcome to continue");
        return;
      }

      if (!this.accept_odds_changes) {

        this.setError("Odds Changed", "Accept Odds Changes to Proceed");
        return;

      }

      var bets = [];

      this.jQuery.each(betslipData.picks, function (k, v) {

        bets.push({
          odd_id: v.odd_id,
          match_id: v.match_id,
        });

      });

      var data = {
        stake: bet_amount,
        bets: bets,
        code: booking_code,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer,
        source: this.isMobile() ? 2 : 1
      };

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_OUTRIGHT_BET.replace("{profile_id}", p.d);

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var profile = res.data.message.profile;
            var message = res.data.message.message;
            vm.setProfile(profile);
            vm.hideBetslip();
            vm.clearOutrightBetSlip();
            vm.setSuccess("Success", message);
            vm.removeObject('booking_code');
            //vm.goHome();

          })
          .catch(err => {

            vm.loading = '';

            if (err.response) {

              console.log(JSON.stringify(err.response,undefined,4));

              var message = "";

              if(parseInt(err.response.status) === 428 ) {

                message = err.response.data.message.message;
                var amount = err.response.data.message.amount;

                vm.EventBus.$emit('deposit:popup',{
                  amount: amount,
                  message:message,
                });

              }

              if(parseInt(err.response.status) === 422 ) {

                message = err.response.data.message;
                vm.setError("Failed", message)
                return
              }

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {
                vm.setError("Failed", message)
              }

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },
    oddStatus: function () {

      var picks = this.betslip.picks;

      if (picks === undefined || picks.length === 0) {

        return;

      }

      var bets = [];

      this.jQuery.each(picks, function (k, v) {

        bets.push(v.odd_id);

      });

      var data = {

        odd_id: bets.join(',')
      };

      if (bets.length === 0) {

        return;

      }

      var vm = this;

      var path = process.env.VUE_APP_URL_OUTRIGHT_ODD_STATUS;

      axios.post(path, JSON.stringify(data))
          .then(res => {

            var bets = res.data.message;

            vm.jQuery.each(bets,function(k,v){

              var oddID = v.id;
              var odds = v.odds;
              var previous_odds = v.previous_odds;
              var status = v.status;
              var active = v.active;
              var producer_status = v.producer_status;

              // get original odds
              // check odds changes
              vm.jQuery.each(picks,function(k1,v1){

                if(parseInt(v1.odd_id) === parseInt(oddID) ) {

                  var oddChangeText = "";
                  odds = vm.formatOdds(odds);

                  if(odds !== vm.formatOdds(v1.odd)) {

                    // odds changed
                    if(odds > v1.odd ) {

                      var direction = 1

                    } else {

                      direction = 0

                    }

                    var directionTxt = parseInt(direction) === 1 ? 'odds has gone up ' : 'odds has gone down ';
                    oddChangeText = directionTxt + ' from '+v1.odd+' to '+odds;

                  }

                  v1.status = status;
                  v1.active = active;
                  v1.odds = odds;
                  v1.odd = odds;
                  v1.previous_odds = previous_odds;
                  v1.producer_status = producer_status;
                  v1.odds_change_text = oddChangeText;
                  picks[k1] = v1;
                }

              })

            });

            vm.saveObject("oslip", picks);
            vm.autoRefreshOutrightUI(vm.$vnode.tag);

          })
          .catch(err => {

            if (err.response) {

              //vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              //vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            } else {

              //vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }
          })
    },
    getOddStatus: function(odd) {

      if(odd.active === 0  || odd.producer_status === 0 ) {

        return '<div class="deactivated-odds">Outcome suspended</div>';

      }

      if(parseInt(odd.status) === 0 || parseInt(odd.status) === -1 ) {

        return '<div class="deactivated-odds">Outcome Deactivated</div>';

      }

      if(odd.odds_change_text && odd.odds_change_text.length > 0 ) {

        return '<div class="odds-change" style="">'+odd.odds_change_text+'</div>';

      }

    },
    initSharebetModal: function(){

      var modal = document.getElementById("sharebet-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("open-sharebet");

      // Get the <span> element that closes the modal
      //var span = document.getElementsByClassName("sharebet-close")[0];

      // When the user clicks on the button, open the modal
      btn.onclick = function() {
        modal.style.display = "block";
      }

      // When the user clicks on <span> (x), close the modal
      /*
      span.onclick = function() {
        modal.style.display = "none";
      }
      */

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function(event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      }

      document.addEventListener("click", e => {

        if (e.target == document.querySelector("#sharebet-modal")) {

          modal.style.display = "none";
        }

      });

    },
    initBetslipModal: function(){

      var vm = this;

      // Get the modal
      var modal = document.getElementById("betslip-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("betslip-init");

      // Get the <span> element that closes the modal
      var span = document.getElementById("faso-close");

      // When the user clicks the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
        vm.previous_odds = vm.betslip.odds;
        vm.oddStatus();
      }

      // When the user clicks on <span> (x), close the modal
      span.onclick = function () {
        modal.style.display = "none";
      }

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        if (event.target == modal) {

          modal.style.display = "none";
        }
      }

      document.addEventListener("click", e => {

        if (e.target === document.querySelector("#betslip-modal")) {

          modal.style.display = "none";
        }

      });

      document.addEventListener("click", e => {

        if (e.target === document.querySelector("#betslip-modal")) {

          modal.style.display = "none";
        }

      });


    },
    showBetslip: function() {

      document.getElementById("betslip-init").click();

    },
    hideBetslip: function() {

      document.getElementById("faso-close").click();

    },
    shareBet: function () {

      var p = this.getProfile();

      var betslipData = this.betslip;

      if (betslipData.total === 0) {

        this.setError("Invalid Bets", "Please Select atleast one outcome to continue");
        return;
      }

      var bets = [];

      this.jQuery.each(betslipData.picks, function (k, v) {

        bets.push({
          market_id: v.market_id,
          match_id: v.match_id,
          outcome_id: v.outcome_id,
          specifier: v.specifier,
        });

      });

      var data = {
        profile_id: p.id,
        bets: bets,
        bet_type: 1
      };

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_SHARE;

      axios.post(path, JSON.stringify(data))
          .then(res => {

            vm.loading = '';
            vm.code = res.data.message;
            vm.share_odds = betslipData.odds;
            vm.shareBetMessage();
            console.log(vm.msg);
            vm.copyText = 'Copy';
            vm.copyBookingCode = 'Click to Copy'
            document.getElementById("open-sharebet").click();

          })
          .catch(err => {

            vm.loading = '';
            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            } else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }
          })
    },
    copyShareBetLink: function () {

      var link = "https://tapabet.co.ke/share/"+this.code;
      this.copyToClipboard(link);
      this.copyText = 'Copied';

    },
    copyCode: function () {

      var link = "book#"+this.code+"#stakeAmount";
      this.copyToClipboard(link);
      this.copyBookingCode = 'Booking Code Copied';

    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    bal: function() {

      return this.formatCurrency(this.profile.b1);

    },

    iconSize: function (){

      return 28;
    },
    profile: function () {

      return this.myProfile;

    },
    liveGames: function (){

      return this.$store.state.live_match_counter

    },
    homePageIcon: function () {

      if (this.page === 'home') {

        return this.getURL("/assets/images/home_icon_selected.svg");

      }

      return this.getURL("/assets/images/home_icon.svg");

    },
    homePageFontColor: function () {

      if (this.page === 'home') {

        return "yellow-txt";
      }

      return "";

    },
    livePageFontColor: function () {

      if (this.page === 'live') {

        return "yellow-txt";
      }

      return "";

    },
    historyPageFontColor: function () {

      if (this.page === 'history') {

        return "yellow-txt";
      }

      return "";

    },
    betslip_count: function () {

      if(!this.betslip.total || this.betslip.total == "") {

        return 0
      }

      return parseInt(this.betslip.total);

    },
    activeBets: function () {

      var p = this.getProfile();
      if(!p) {

        return 0;

      }

      return p.b

    },
    odds: function () {

      return this.betslip.odds
      //return this.$store.state.betslip.odds

    },
    payout: function () {

      return this.betslip.payout

    },
    betslip: function () {

      return this.$store.state.outrightbetslip
    },
    has_suspended_picks: function (){

      return this.$store.state.has_suspended_outright_picks

    },
    isLoggedIn: function (){

      var p = this.getProfile();
      if(!p) {
        return false;
      }

      return true;
    },
    modalClass: function () {

      if(parseInt(this.betslip_count) === 0 ) {

        return  "faso-modal-booking-code"

      } else {

        return  "faso-modal"

      }
    },
    modalContentClass: function () {

      if(parseInt(this.betslip_count) === 0 ) {

        return  "faso-modal-content-booking-code"

      } else {

        return  "faso-modal-content"
      }

    }

  },
  filters: {

    currency: function (amount) {

      if (amount == null) {

        amount = 0;
      }

      return (amount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'KES',
      });

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    }
  },
  watch: {

    stake: function (newValue) {

      // update stake
      this.setValue("stake", newValue);

      // recalculate winnings
      this.autoRefreshOutrightUI(this.$vnode.tag);

    }
  },
  components: {
    Pick,
    MenuIcon,
    ListIcon
  },
  mounted: function () {

    // reset stake
    this.setValue("stake",50);
    this.initBetslipModal();
    this.previous_odds = this.betslip.odds;

    this.$store.dispatch("getLiveMatchCounter");
    var vm = this;

    this.EventBus.$on('event:outrightbetslip:show',function(){

      vm.showBetslip();

    });

    this.EventBus.$on('event:outrightbetslip:hide',function(){

      vm.hideBetslip();

    });

    this.EventBus.$on('event:outrightbetslip:placeBet',function(){

      vm.placeBet(true);

    });

    this.EventBus.$on('profile:balance',function(payload){

      vm.myProfile = payload;

    });

    this.EventBus.$on('outrightodds:changed',function(payload){

      vm.odds_changed = true;
      vm.updateOutrightOdd(payload.id,payload.odds,payload.previous_odds,payload.active,payload.status);

    });

  }
}
</script>
